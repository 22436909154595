
  import { defineComponent } from "vue"

  export default defineComponent({
    props: {
      data: {
        type: Object,
        require: true
      },
    },
    setup(props) {
      const cellsMapping = (data) => {
        const value = data.value
        const mappedIcons = data.field.options.mappedIcons
        return mappedIcons.find(mapping => mapping.value === value)
      }

      const cellStack = (data) => cellsMapping(data)?.stack

      const isIcon = (stackElement) => stackElement.prefix && stackElement.icon
      
      return {
        cellStack,
        isIcon
      }
    }
  })
